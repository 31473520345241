import React, { useState, useEffect } from 'react';
import { getDatabase, push, ref, set } from "firebase/database";
    import { initializeApp } from 'firebase/app';
    import { getAnalytics } from "firebase/analytics";

    const firebaseConfig = {
        apiKey: "AIzaSyDHoZm6NpIqRUPaexoUO266rfEsBEGtGdI",
        authDomain: "uran-redef.firebaseapp.com",
        databaseURL: "https://uran-redef.firebaseio.com",
        projectId: "uran-redef",
        storageBucket: "uran-redef.appspot.com",
        messagingSenderId: "900713737842",
        appId: "1:900713737842:web:ea804e49b811149528dceb",
        measurementId: "G-KR28BJJVHP"
      }

      
const DeleteAccount = () => {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [email, setEmail] = useState('');
    
    const app = initializeApp(firebaseConfig); 





    useEffect(() => {

         
const analytics = getAnalytics(app);

        // Check if email is present in the params
        const params = new URLSearchParams(window.location.search);
        const emailParam = params.get('email');
        if (emailParam) {
            setEmail(emailParam);
        }
    }, []);

    const handleDelete = () => {
        // Logic to delete the account goes here
        // You can make an API call or perform any necessary operations
        // Remember to handle success and error cases accordingly
       
        writeUserData(email)
    
    };

    function writeUserData(email) {
        const db = getDatabase();
        const timestamp = new Date().getTime();
        push(ref(db, 'deleteUser/'), {
          email: email,
          timestamp: timestamp,
          deletedStatus: false
        }).then(() => {
          alert('Request to delete account has been sent.');
          window.location.reload();
        }).catch((error) => {
          console.error('Error sending delete account request:', error);
        });
    }

    return (
        <center>
            <h1>Delete Account</h1>
            <h3>Are you sure you want to delete your account?</h3>
            <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            /> 
            <br />
            <br />
            <button onClick={() => {
                 if(email.length >0 ){
                    setConfirmDelete(true)
            }
        else{
            alert('Please enter your email');
        }
            
          }
            }>Yes, I want to delete my account</button>
            {confirmDelete && (
                <div>
                    <p>Confirm your decision:</p>
                    <button onClick={handleDelete}>Delete Account</button>
                </div>
            )}
        </center>
    );
};

export default DeleteAccount;