import logo from './assets/vector.svg';
import google from './assets/GetItOnGooglePlay_Badge_Web_color_English.png';
import './App.css';



function App() {
  return (<div className="App">






    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p>
<ol>
<li>
News 
</li>
<li>
NMMT Live
</li>
</ol>
      </p>
      <a
        className="App-link"
        href="https://play.google.com/store/apps/details?id=com.local.city"
        target="_blank"
        rel="noopener noreferrer"
      >
<img src={google} alt="logo" />
      </a>


<a href="/privacypolicy">    <span>Privacy Policy</span></a>
    </header>

  </div>);
}

export default App;
